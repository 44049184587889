<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex  justify-content-end flex-wrap mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-auto"
            type="button"
            variant="primary"
            @click="create"
          >
            <feather-icon icon="PlusIcon" size="14" />
            <span> Create payment method </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <AppTable
      :rows="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: paymentMethods.limit,
        count: paymentMethods.count,
        page: paymentMethods.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="4" xl="4" class="mb-1">
            <AssetFilter
              :key="selectAsset"
              label="Currency"
              placeholder="fiat currency"
              v-model="selectAsset"
              @change="changeAsset"
              :is-crypto-currency="false"
            />
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group>
              <label for="status">Status</label>
              <v-select
                v-model="selectStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="statusOptions"
                placeholder="set status"
                @input="changeStatus"
              />
            </b-form-group>
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="method" type="text" />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow }">
        <span v-if="column.field === 'status'" class="flex-center-align">
          <b-badge :variant="row.status.theme" class="text-white">
            {{ row.status.text }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'action'" class="d-flex">
          <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="edit(row)">
            <span class="text-white"> Edit </span>
          </b-button>
        </span>

        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

import { secondsToReadableFormat } from '@/tools/util'

import store from '@/store/index'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import AppTable from '@/components/AppTable.vue'
import AssetFilter from '@/components/containers/AssetFilter.vue'

import columns from './config/tableConfig'

export default {
  components: {
    BBadge,
    vSelect,
    AssetFilter,
    AppTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,

      selectAsset: '',
      searchTerm: '',
      selectStatus: { text: '--', isActive: null, id: 0 },
      statusOptions: [
        { text: '--', isActive: null, id: 0 },
        { text: 'active', isActive: true, id: 1 },
        { text: 'not active', isActive: false, id: 2 },
      ],
    }
  },

  computed: {
    ...mapGetters({
      paymentMethods: 'paymentMethods/paymentMethods',
    }),

    rows() {
      return this.transformRows(this.paymentMethods.value || [])
    },

    direction() {
      return store.state.appConfig.isRTL
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    secondsToReadableFormat,
    ...mapActions({
      fetchPaymentMethods: 'paymentMethods/fetchPaymentMethods',
      deletePaymentMethodById: 'paymentMethods/deletePaymentMethodById',
    }),

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      this.fetchPaymentMethods(query)
    },

    search() {
      const query = this.createQueryConfig({ search: this.searchTerm })

      return this.getPaymentMethods(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getPaymentMethods(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getPaymentMethods(query)
    },

    changeAsset({ id = '' } = {}) {
      const query = this.createQueryConfig({ fiatId: id })

      return this.getPaymentMethods(query)
    },

    changeStatus(status) {
      const query = this.createQueryConfig({ isActive: status.isActive })

      return this.getPaymentMethods(query)
    },

    createQueryConfig(config) {
      return {
        page: this.paymentMethods.page,
        limit: this.paymentMethods.limit,

        isActive: this.selectStatus?.isActive ?? undefined,
        search: this.searchTerm || undefined,
        fiatId: this.selectAsset?.id || undefined,

        ...config,
      }
    },

    getPaymentMethods(query) {
      return this.waitRequest(() => {
        return this.fetchPaymentMethods(query).catch(this.checkErrorsAlert)
      })
    },

    create() {
      this.$router.push({ path: '/payment-methods/create' })
    },

    edit(row) {
      this.$router.push({ path: `/payment-methods/${row.id}` })
    },

    deletePaymentMethod(id) {
      console.log('delete id', id)
      // return this.waitRequest(() => {
      //   return this.deletePaymentMethodById(id).catch(this.checkErrorsAlert)
      // })
    },

    transformRows(rows) {
      const theme = {
        active: 'success',
        'not active': 'info',
      }

      return rows.map(rowData => {
        const statusText = rowData.isActive ? 'active' : 'not active'
        const status = {
          text: statusText,
          theme: theme[statusText],
        }

        const { flag = '', name = '' } = rowData.fiat || {}
        const fiat = `${flag || ''} ${name || ''}`

        const transactionLimitTimeMin = this.secondsToReadableFormat(rowData.transactionLimitTimeMin)
        const transactionLimitTimeMax = this.secondsToReadableFormat(rowData.transactionLimitTimeMax)

        return {
          ...rowData,
          status,
          transactionLimitTimeMin,
          transactionLimitTimeMax,
          fiat,
        }
      })
    },
  },
}
</script>
