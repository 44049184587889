export default [
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Fiat',
    field: 'fiat',
  },
  {
    label: 'Time Min',
    field: 'transactionLimitTimeMin',
  },
  {
    label: 'Time Max',
    field: 'transactionLimitTimeMax',
  },
  {
    label: 'Limit cost',
    field: 'transactionLimitCost',
  },
  {
    label: 'Icon',
    field: 'icon',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Action',
    field: 'action',
  },
]

export const feesTable = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Fee',
    field: 'fee',
  },
  {
    label: 'Coin',
    field: 'coin',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
